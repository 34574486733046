import { preloadFonts } from "./utils";
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

Splitting();
const fx16Titles = [
  ...document.querySelectorAll(
    ".content__title[data-splitting][data-effect16]"
  ),
];
const fxLines = [...document.querySelectorAll("[data-lines]")];
const fxMarqueeLeft = [...document.querySelectorAll("[data-marquee-l]")];
const fxMarqueeRight = [...document.querySelectorAll("[data-marquee-r]")];
const fxFadeTitles = [
  ...document.querySelectorAll(
    ".content__title[data-splitting][data-fadetitles]"
  ),
];
const fxFadeTitlesHero = [
  ...document.querySelectorAll(
    ".content__title[data-splitting][data-fadetitles-hero]"
  ),
];
const fxScaleUp = [...document.querySelectorAll("[data-scale-up]")];
const fxScrollUp = [...document.querySelectorAll("[data-scroll-up]")];
const fxFadeIn = [...document.querySelectorAll("[data-fade-in-text]")];
const fxMaskUp = [
  ...document.querySelectorAll(".content__title[data-mask-up]"),
];

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
  lenis = new Lenis({
    lerp: 0.2,
    smooth: true,
  });

  lenis.on("scroll", () => ScrollTrigger.update());

  const scrollFn = (time) => {
    lenis.raf(time);
    requestAnimationFrame(scrollFn);
  };

  requestAnimationFrame(scrollFn);
};

//responsive
let mm = gsap.matchMedia();

// GSAP Scroll Triggers
const scroll = () => {
  fx16Titles.forEach((title) => {
    gsap.fromTo(
      title,
      {
        transformOrigin: "0% 50%",
        rotate: 0,
      },
      {
        ease: "none",
        rotate: 0,
        scrollTrigger: {
          trigger: title,
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      title.querySelectorAll(".word"),
      {
        "will-change": "opacity",
        opacity: 0.2,
      },
      {
        ease: "none",
        opacity: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: title,
          start: "top bottom-=10%",
          end: "center top+=40%",
          scrub: true,
        },
      }
    );
  });

  fxFadeTitles.forEach((title) => {
    gsap.fromTo(
      title,
      {
        transformOrigin: "0% 50%",
        rotate: 0,
      },
      {
        ease: "none",
        rotate: 0,
        scrollTrigger: {
          trigger: title,
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      title.querySelectorAll(".char"),
      {
        "will-change": "opacity",
        y: 100,
      },
      {
        ease: "power3",
        y: 0,
        stagger: 0.05,
        scrollTrigger: {
          trigger: title,
          start: "top bottom-=20%",
          end: "center top+=20%",
          scrub: false,
        },
      }
    );
  });

  fxFadeIn.forEach((title) => {
    gsap.fromTo(
      title.querySelectorAll(".text-fade"),
      {
        "will-change": "opacity",
        opacity: 0,
        y: 16,
      },
      {
        ease: "power3",
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.2,
        stagger: 0.1,
        scrollTrigger: {
          trigger: title,
          start: "top bottom-=20%",
          end: "center top+=20%",
          scrub: false,
        },
      }
    );
  });

  // fxFadeTitlesHero.forEach((title) => {
  //   gsap.fromTo(
  //     title,
  //     {
  //       transformOrigin: "0% 50%",
  //       rotate: 2,
  //     },
  //     {
  //       ease: "power2",
  //       rotate: 0,
  //       delay: 3.4,
  //       duration: 0.6,
  //       stagger: 0.3,
  //       scrollTrigger: {
  //         trigger: title,
  //         start: "top bottom",
  //         end: "top top",
  //         scrub: false,
  //       },
  //     }
  //   );

  //   gsap.fromTo(
  //     title.querySelectorAll(".char"),
  //     {
  //       "will-change": "opacity",
  //       y: 130,
  //     },
  //     {
  //       delay: 4,
  //       ease: "power4",
  //       y: 0,
  //       stagger: 0.05,
  //       scrollTrigger: {
  //         trigger: title,
  //         start: "top bottom-=20%",
  //         end: "center top+=20%",
  //         scrub: false,
  //       },
  //     }
  //   );
  // });

  fxLines.forEach((title) => {
    gsap.fromTo(
      title.querySelectorAll(".line"),
      {
        "will-change": "opacity",
        transformOrigin: "0% 0%",
        scaleX: 0,
      },
      {
        ease: "power4",
        scaleX: 1,
        stagger: 0.2,
        delay: 0.4,
        duration: 0.6,
        scrollTrigger: {
          trigger: title,
          start: "top bottom",
          end: "top top",
          scrub: false,
        },
      }
    );
  });

  fxMarqueeLeft.forEach((title) => {
    gsap.fromTo(
      title,
      {
        transformOrigin: "0% 50%",
        x: -30,
      },
      {
        ease: "none",
        x: -400,
        scrollTrigger: {
          trigger: title,
          start: "top bottom+=20%",
          end: "bottom top-=20%",
          scrub: true,
        },
      }
    );
  });

  fxMarqueeRight.forEach((title) => {
    gsap.fromTo(
      title,
      {
        transformOrigin: "50% 50%%",
        x: -400,
      },
      {
        ease: "none",
        x: +30,
        scrollTrigger: {
          trigger: title,
          start: "top bottom+=20%",
          end: "bottom top-=20%",
          scrub: true,
        },
      }
    );
  });

  fxScaleUp.forEach((title) => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1025px)", () => {
      gsap.fromTo(
        title,
        {
          transformOrigin: "50% 50%%",
          scale: 1,
        },
        {
          ease: "none",
          scale: 1.1,
          scrollTrigger: {
            trigger: title,
            start: "top bottom",
            end: "top top",
            scrub: true,
          },
        }
      );
    });
  });

  fxScrollUp.forEach((title) => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1025px)", () => {
      gsap.fromTo(
        title,
        {
          transformOrigin: "50% 50%%",
          y: 80,
          "will-change": "transform",
        },
        {
          ease: "none",
          y: -80,
          scrollTrigger: {
            trigger: title,
            start: "top bottom+=20%",
            end: "bottom top-=20%",
            scrub: true,
          },
        }
      );
    });
  });
};

// Preload images and fonts
preloadFonts("cvn8slu").then(() => {
  // Remove loader (loading class)
  setTimeout(function () {
    document.body.classList.remove("loading");
  }, 2000); // Adjust the delay to match your desired timing
  const transitionContainer = document.querySelector(".transition-container");

  setTimeout(function () {
    transitionContainer.classList.add("fade-in");
    // Lenis (smooth scrolling)
    initSmoothScrolling();
    // GSAP Scroll Triggers
    scroll();
  }, 3000); // Adjust the delay to match your desired timing

  const links = document.querySelectorAll(".navlink");

  links.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.preventDefault();

      transitionContainer.classList.add("fade-out");

      setTimeout(function () {
        window.location.href = link.href;
      }, 1000); // Adjust the delay to match the transition duration
    });
  });
  // Lenis (smooth scrolling)
  initSmoothScrolling();
  // GSAP Scroll Triggers
  scroll();
});

// JavaScript
let prevScrollY = window.pageYOffset;

function handleScroll() {
  const currentScrollY = window.pageYOffset;

  if (currentScrollY > prevScrollY) {
    // Scrolling down, add the fade-out class
    document.querySelectorAll(".navbar-bg").forEach((element) => {
      element.classList.add("fade-out-on-scroll");
    });
  } else {
    // Scrolling up, remove the fade-out class
    document.querySelectorAll(".navbar-bg").forEach((element) => {
      element.classList.remove("fade-out-on-scroll");
    });
  }

  prevScrollY = currentScrollY;
}

window.addEventListener("scroll", handleScroll);

document.addEventListener("DOMContentLoaded", function () {
  const progressBar = document.querySelector(".progress-bar");

  window.addEventListener("scroll", function () {
    const scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollProgress = (scrollTop / scrollableHeight) * 100;

    progressBar.style.width = scrollProgress + "%";

    if (scrollProgress > 0) {
      progressBar.style.display = "block"; // Show the progress bar
    } else {
      progressBar.style.display = "none"; // Hide the progress bar
    }
  });
});

const btn = document.querySelector("button.mobile-menu-button");
const menu = document.querySelector(".mobile-menu");

btn.addEventListener("click", () => {
  menu.classList.toggle("hidden");
});
